import React from "react"
import "../style/refer.css"
import Layout from "../components/layout"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class referHelper extends React.Component {
    constructor() {
        super()
        this.getCookie = this.getCookie.bind(this);
    }
    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    residentScript = () =>{
        var name = document.getElementById("contact_name").value.trim();
        var email = document.getElementById("contact_email").value.trim();
        var phone = document.getElementById("contact_phone").value.trim();
        var apartment = document.getElementById("contact_apartment").value.trim();
        var project = document.getElementById("contact_project").value.trim();
        var project2 = document.getElementById("contact_project2").value.trim();
        //referral details
        var name2 = document.getElementById("ref_name").value.trim();
        var email2 = document.getElementById("ref_email").value.trim();
        var phone2 = document.getElementById("ref_phone").value.trim();
        var project3 = document.getElementById("contact_project3").value.trim();
        var project4 = document.getElementById("contact_project4").value.trim();
        // UTM DATA
        // Populate Cookies data
        var utmSource = this.getCookie("sourcey");
        var utmMedium = this.getCookie("mediumy");
        var utmCampaign = this.getCookie("campaigny");
        var utmTerm =  this.getCookie("termy");
        var utmContent =  this.getCookie("contenty"); 
       
    
        // Email Format
        var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
         
        if(name == ""){
            alert("Enter you Name");
        }
        else if(name2 == ""){
            alert("Enter the Referral's name");
        }
        else if(phone.toString().length != 10){
            alert("Enter a valid Phone number");
        }
        else if(phone2.toString().length != 10){
            alert("Enter a valid Referral's Phone number");
        }
        else if(reg.test(email) == false || reg.test(email2) == false){
            alert("Enter a valid Email");
        }
        else{
                var HttpClient = function() {
                this.get = function(aUrl, aCallback) {
                var anHttpRequest = new XMLHttpRequest();
                anHttpRequest.onreadystatechange = function() { 
                    if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                        aCallback(anHttpRequest.responseText);
                }
                anHttpRequest.open( "GET", aUrl, true ); 
                anHttpRequest.send( null ); 
                }
            }
            var theurl='https://script.google.com/macros/s/AKfycbxfekfsdPK2WBNl23mocohE_26gWOBvIkmYEanqcAuyTPVV0vucNMV9NA/exec?name=' + name +"&email=" +email+ "&phone=" +phone+ "&apartment=" +apartment+ "&project=" +project+ "&project2=" +project2+ "&name2=" + name2 +"&email2=" +email2+ "&phone2=" +phone2+ "&project3=" +project3+ "&project4=" +project4+ "&utmSource=" + utmSource + "&utmMedium=" + utmMedium + "&utmContent=" + utmContent + "&utmTerm=" + utmTerm + "&utmCampaign=" + utmCampaign;
            var client = new HttpClient();
            client.get(theurl, function(response) { 
                var response1 = JSON.parse(response);
                //document.getElementById("print").innerHTML = response1.status ;
            });  
            window.location.replace("/thankyou");
        }
    }
    employeeScript = () => {
        var ename = document.getElementById("contact_name1").value.trim();
        var eemail = document.getElementById("contact_email1").value.trim();
        var ephone = document.getElementById("contact_phone1").value.trim();
        var eapartment = document.getElementById("tab2_employeeid").value.trim();
        var eproject = document.getElementById("tab2_department").value.trim();
        var eproject2 = document.getElementById("tab2_designation").value.trim();
        //referral details
        var erefer = document.getElementById("tab2_name2").value.trim();
        var eemail2 = document.getElementById("tab2_email2").value.trim();
        var ephone2 = document.getElementById("tab2_phone2").value.trim();
        var eproject3 = document.getElementById("tab2_project3").value.trim();
        var eproject4 = document.getElementById("tab2_project4").value.trim();


        // UTM DATA
        // Populate Cookies data
        var utmSource = this.getCookie("sourcey");
        var utmMedium = this.getCookie("mediumy");
        var utmCampaign = this.getCookie("campaigny");
        var utmTerm = this.getCookie("termy");
        var utmContent = this.getCookie("contenty");

        // Email Format
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (ename == "") {
            alert("Enter your Name");
        }
        else if(eapartment == ""){
            alert("Enter a valid Employee ID");
        }
        else if(eproject == ""){
            alert("Enter your Department");
        }
        else if(eproject2 == ""){
            alert("Enter your Designation");
        }
        else if (erefer == "") {
            alert("Enter Referral Name");
        }
        else if (ephone.toString().length != 10) {
            alert("Enter a valid Phone number");
        }
        else if (ephone2.toString().length != 10) {
            alert("Enter a valid Referral Number");
        }
        else if (eemail.match(mailformat) == false) {
            alert("Enter a valid Email");
        }
        else if (eemail2.match(mailformat) == false) {
            alert("Enter a valid Reference Email");
        } 
        else {
            var HttpClient = function () {
                this.get = function (aUrl, aCallback) {
                    var anHttpRequest = new XMLHttpRequest();
                    anHttpRequest.onreadystatechange = function () {
                        if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                            aCallback(anHttpRequest.responseText);
                    }
                    anHttpRequest.open("GET", aUrl, true);
                    anHttpRequest.send(null);
                }
            }
            var theurl = 'https://script.google.com/macros/s/AKfycbyfkQqQt1AcKYS3_9K96vllyfYOdFf8E55bMlYMPb0qt1bk4BjGj2I6/exec?tab2_name=' + ename + "&tab2_email=" + eemail + "&tab2_phone=" + ephone + "&tab2_employeeid=" + eapartment + "&tab2_department=" + eproject + "&tab2_designation=" + eproject2 + "&tab2_name2=" + erefer + "&tab2_email2=" + eemail2 + "&tab2_phone2=" + ephone2 + "&tab2_project3=" + eproject3 + "&tab2_project4=" + eproject4 + "&utmSource=" + utmSource + "&utmMedium=" + utmMedium + "&utmContent=" + utmContent + "&utmTerm=" + utmTerm + "&utmCampaign=" + utmCampaign;
            var client = new HttpClient();
            client.get(theurl, function (response) {
                var response1 = JSON.parse(response);
                //document.getElementById("print").innerHTML = response1.status ;
            }); 
            window.location.replace("/thankyou");
        }
    }

    vendorScript = () =>{
        var vname = document.getElementById("vendor_name").value.trim();
        var vemail = document.getElementById("vendor_email").value.trim();
        var vphone = document.getElementById("vendor_phone").value.trim();
        var vdesignation = document.getElementById("vendor_desgination").value.trim();
        var vvlocation = document.getElementById("vendor_location").value.trim();
        var vdropdown1 = document.getElementById("vendor_drop1").value.trim();
        //referral details
        var vname2 = document.getElementById("vendor_rname").value.trim();
        var vemail2 = document.getElementById("vendor_remail").value.trim();
        var vvphone2 = document.getElementById("vendor_rphone").value.trim();
        var vdropdown2 = document.getElementById("vendor_drop2").value.trim();
        var vdropdown3 = document.getElementById("vendor_drop3").value.trim();
    
    
      // UTM DATA
        // Populate Cookies data
        var utmSource = this.getCookie("sourcey");
        var utmMedium = this.getCookie("mediumy");
        var utmCampaign = this.getCookie("campaigny");
        var utmTerm = this.getCookie("termy");
        var utmContent = this.getCookie("contenty");
    
        // Email Format
        var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
         
        if(vname == ""){
            alert("Enter your Name");
        }
        
        else if(vname2 == ""){
            alert("Enter the Referral's name");
        }
        else if(reg.test(vemail) == false){
            alert("Enter a valid Email");
        }
        else if(reg.test(vemail2) == false){
            alert("Enter a valid Referral Email");
        }
        else if(vphone.toString().length != 10){
            alert("Enter a valid Phone number");
        }
        else if(vvphone2.toString().length != 10){
            alert("Enter a valid Referral Phone number");
        }
    
        else if(vdesignation == ""){
            alert("Enter your Designation")
        }
        else if(vvlocation ==""){
            alert("Enter the Location")
        }
        
        else{
                var HttpClient = function() {
                this.get = function(aUrl, aCallback) {
                var anHttpRequest = new XMLHttpRequest();
                anHttpRequest.onreadystatechange = function() { 
                    if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                        aCallback(anHttpRequest.responseText);
                }
                anHttpRequest.open( "GET", aUrl, true ); 
                anHttpRequest.send( null ); 
                }
            }
            var theurl='https://script.google.com/macros/s/AKfycbxoJi7wm7p6NKH2Fo_wngIBaKgbnHWcbaQDnBbIbsSWLQ1m0QN5_sYd8A/exec?vname=' + vname +"&vemail=" +vemail+ "&vphone=" +vphone+ "&vdesignation=" +vdesignation+ "&vvlocation=" +vvlocation+ "&vdropdown1=" +vdropdown1+ "&vname2=" + vname2 +"&vemail2=" +vemail2+ "&vvphone2=" +vvphone2+ "&vdropdown2=" +vdropdown2+ "&vdropdown3=" +vdropdown3+ "&utmSource=" + utmSource + "&utmMedium=" + utmMedium + "&utmContent=" + utmContent + "&utmTerm=" + utmTerm + "&utmCampaign=" + utmCampaign;
            var client = new HttpClient();
            client.get(theurl, function(response) { 
                var response1 = JSON.parse(response);
                //document.getElementById("print").innerHTML = response1.status ;
            });   
            window.location.replace("/thankyou");
        }
    }

    otherScript = () =>{
        var oname = document.getElementById("other_name").value.trim();
        var oemail = document.getElementById("other_oemail").value.trim();
        var ophone = document.getElementById("other_ophone").value.trim();
        var oolocation = document.getElementById("other_olocation").value.trim();
        var odropdown1 = document.getElementById("other_odropdown1").value.trim();
        //referral details
        var oname2 = document.getElementById("rother_name").value.trim();
        var oemail2 = document.getElementById("rother_email").value.trim();
        var oophone2 = document.getElementById("rother_phone").value.trim();
        var odropdown2 = document.getElementById("rother_dropdown2").value.trim();
        var odropdown3 = document.getElementById("rother_dropdown3").value.trim();
    
    
        // UTM DATA
        // Populate Cookies data
        var utmSource = this.getCookie("sourcey");
        var utmMedium = this.getCookie("mediumy");
        var utmCampaign = this.getCookie("campaigny");
        var utmTerm = this.getCookie("termy");
        var utmContent = this.getCookie("contenty");
    
    
        // Email Formatbats
        var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
         
        if(oname == ""){
            alert("Enter your Name");
        }
        
        else if(oname2 == ""){
            alert("Enter the Referral's name");
        }
        else if(reg.test(oemail) == false){
            alert("Enter a valid Email");
        }
        else if(reg.test(oemail2) == false){
            alert("Enter a valid Referral Email");
        }
        else if(ophone.toString().length != 10){
            alert("Enter a valid Phone number");
        }
        else if(oophone2.toString().length != 10){
            alert("Enter a valid Referral Phone number");
        }
        else if(oolocation ==""){
            alert("Enter the Location")
        }
        
        else{
                var HttpClient = function() {
                this.get = function(aUrl, aCallback) {
                var anHttpRequest = new XMLHttpRequest();
                anHttpRequest.onreadystatechange = function() { 
                    if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                        aCallback(anHttpRequest.responseText);
                }
                anHttpRequest.open( "GET", aUrl, true ); 
                anHttpRequest.send( null ); 
                }
            }
            var theurl='https://script.google.com/macros/s/AKfycbzJVZ8S20SUwaZbErf31h2_CTCJuz2RGdjTtyx9COz4g1kg4q0CNjONtA/exec?oname=' + oname +"&oemail=" +oemail+ "&ophone=" +ophone+ "&oolocation=" +oolocation+ "&odropdown1=" +odropdown1+ "&oname2=" + oname2 +"&oemail2=" +oemail2+ "&oophone2=" +oophone2+ "&odropdown2=" +odropdown2+ "&odropdown3=" + odropdown3 + "&utmSource=" + utmSource + "&utmMedium=" + utmMedium + "&utmContent=" + utmContent + "&utmTerm=" + utmTerm + "&utmCampaign=" + utmCampaign;
            var client = new HttpClient();
            client.get(theurl, function(response) { 
                var response1 = JSON.parse(response);
                //document.getElementById("print").innerHTML = response1.status ;
            });  
            window.location.replace("/thankyou");
        }
    }

    render() {
        return (
            <Layout>
                <br /><br /><br /><br />
                <div className="row">
                    <div className="col-md-12">
                        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" className="w-100" >
                            <source src="https://sumadhuragroup.com/wp-content/uploads/2022/04/Sumadhura-Referral-Video.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="container  pt-5">
                    {/*  REFER */}
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="text-center cus-ref lh-2">"A good neighbor is a fellow who smiles at you over the back fence, its even better when neighbor is friend or family"</p>
                            <button className="cus-btn-ref">Refer Us Now</button>
                        </div>
                    </div>
                    {/* TAB BUTTON */}
                    <div className="container pt-5 pb-5">
                        <Tabs>
                            <TabList>
                                <Tab><span className="t-m">SUMADHURA RESIDENT</span></Tab>
                                <Tab><span className="t-m">SUMADHURA EMPLOYEE</span></Tab>
                                <Tab><span className="t-m">VENDORS</span></Tab>
                                <Tab><span className="t-m">OTHERS</span></Tab>
                            </TabList>
                            <hr style={{ borderTop: "solid 1px #aaa" }} />
                            <TabPanel>
                                <div className="row tab-1">
                                    <div className="col-md-6">
                                        <div className="form-group  w-85">
                                            <h4 className="aspireblue" > Please fill Your details </h4> <br />
                                            <label for="Name">Your Name*</label>
                                            <input type="text" className="form-control" id="contact_name" placeholder="Your Name" name="text" />
                                            <br />

                                            <label for="email">Your Email*</label>
                                            <input type="email" className="form-control" id="contact_email" placeholder="Your email" name="email" />
                                            <br />
                                            <label for="email">Your Mobile Number*</label>
                                            <input type="number" className="form-control" id="contact_phone" placeholder="Your Mobile Number" name="number" />
                                            <br />
                                            <label for="email">Your Apartment Number*</label>
                                            <input type="text" className="form-control" id="contact_apartment" placeholder="Apartment Number(A-1001)" name="text" />
                                            <br />
                                            <div className="form-group">
                                                <label for="contact_project">Residing In Which Project:*</label>
                                                <select className="form-control" id="contact_project">
                                                    <option>Sumadhura Eden Garden</option>
                                                    <option>Sumadhura Nandanam</option>

                                                    <option>Sumadhura Soham </option>
                                                    <option>Sumadhura Sushantam</option>

                                                    <option>Sumadhura Acropolis</option>
                                                    <option>Sumadhura Horizon</option>
                                                    <option>Aspire Aurum</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label for="contact_project2">How did you hear about this Program?*</label>
                                                <select className="form-control" id="contact_project2">
                                                    <option>Newspaper</option>
                                                    <option>Telivision</option>
                                                    <option>Radio</option>
                                                    <option>Outdoor Hoarding</option>
                                                    <option>Email</option>
                                                    <option>SMS</option>
                                                    <option>Facebook/Social Media</option>
                                                    <option>Family and Friends</option>
                                                    <option>Website</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <h4 className="aspireblue" >Referral Information </h4>
                                        <div class="form-group  w-85">
                                            <br />
                                            <label for="Name">Referral Name:*</label>
                                            <input type="text" class="form-control" id="ref_name" placeholder="Referral Name" name="text" />
                                            <br />

                                            <label for="email">Referral Email:*</label>
                                            <input type="email" class="form-control" id="ref_email" placeholder="Referral email" name="email" />
                                            <br />

                                            <label for="email">Referral Number:*</label>
                                            <input type="text" class="form-control" id="ref_phone" placeholder="Referral Number" name="number" />
                                            <br />
                                            <div class="form-group">
                                                <label for="contact_project3">Project Want to Refer:*</label>
                                                <select class="form-control" id="contact_project3">
                                                    <option selected>Sumadhura Eden Garden</option>
                                                    <option>Sumadhura Nandanam</option>
                                                    <option>Sumadhura Sushantam</option>
                                                    <option>Sumadhura Soham Phase-2</option>
                                                    <option>Aspire Aurum</option>
                                                    <option>Sumadhura Acropolis</option>
                                                    <option>Sumadhura Horizon</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="contact_project4">Unit Type:*</label>
                                                <select class="form-control" id="contact_project4">
                                                    <option selected>1 BHK</option>
                                                    <option>2 BHK</option>
                                                    <option>2.5 BHK</option>
                                                    <option>3 BHK</option>
                                                    <option>3.5 BHK</option>

                                                </select>
                                            </div>
                                            <br />
                                        </div>

                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="row pb-5 mf-e" >
                                    <div className="col-md-12 text-center">
                                        <button className="btn btn-dark md-10" onClick={()=>this.residentScript()}>Submit Details</button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row tab-1 text-justify" >
                                    <div class="col-md-6">
                                        <h4 className="aspireblue" > Please fill Your details </h4>
                                        <div class="form-group  w-85">
                                            <br />
                                            <label for="Name">Your Name*</label>
                                            <input type="text" class="form-control" id="contact_name1" placeholder="Your Name" name="text" />
                                            <br />

                                            <label for="email">Your Email*</label>
                                            <input type="email" class="form-control" id="contact_email1" placeholder="Your email" name="email" />
                                            <br />
                                            <label for="email">Your Mobile Number*</label>
                                            <input type="Number" class="form-control" id="contact_phone1" placeholder="Your Mobile Number" name="number" />
                                            <br />
                                            <label for="email">Your Employee ID*</label>
                                            <input type="text" class="form-control" id="tab2_employeeid" placeholder="Employee ID (1548)" name="number" />
                                            <br />

                                            <label for="email">Department*</label>
                                            <input type="text" class="form-control" id="tab2_department" placeholder="Eg : HR, etc..." name="text" />
                                            <br />
                                            <label for="email">Your Designation*</label>
                                            <input type="text" class="form-control" id="tab2_designation" placeholder="Eg : Marketing Executive" name="text" />
                                        </div>
                                    </div>



                                    <div class="col-md-6">
                                        <h4 className="aspireblue" > Referral Information </h4>
                                        <div class="form-group  w-85">
                                            <br />
                                            <label for="Name">Referral Name:*</label>
                                            <input type="text" class="form-control" id="tab2_name2" placeholder="Referral Name" name="text" />
                                            <br />

                                            <label for="email">Referral Email:*</label>
                                            <input type="email" class="form-control" id="tab2_email2" placeholder="Referral email" name="email" />
                                            <br />

                                            <label for="email">Referral Number:*</label>
                                            <input type="text" class="form-control" id="tab2_phone2" placeholder="Referral Number" name="number" />
                                            <br />
                                            <div class="form-group">
                                                <label for="tab2_project3">Project Want to Refer:*</label>
                                                <select class="form-control" id="tab2_project3">
                                                    <option selected>Sumadhura Eden Garden</option>
                                                    <option>Sumadhura Nandanam</option>
                                                    <option>Sumadhura Sushantam</option>
                                                    <option>Sumadhura Soham Phase-2</option>
                                                    <option>Aspire Aurum</option>
                                                    <option>Sumadhura Acropolis</option>
                                                    <option>Sumadhura Horizon</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="tab2_project4">Unit Type:*</label>
                                                <select class="form-control" id="tab2_project4">
                                                    <option selected>1 BHK</option>
                                                    <option>2 BHK</option>
                                                    <option>2.5 BHK</option>
                                                    <option>3 BHK</option>
                                                    <option>3.5 BHK</option>

                                                </select>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="row pb-5 mf-e" >
                                    <div className="col-md-12 text-center">
                                        <button className="btn btn-dark md-10" onClick={() => this.employeeScript()}>Submit Details</button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row tab-1" id="tb3">
                                    <div class="col-md-6">
                                        <h4 className="aspireblue" > Please fill Your details </h4>
                                        <div class="form-group  w-85">
                                            <br />
                                            <label for="Name">Your Name*</label>
                                            <input type="text" class="form-control" id="vendor_name" placeholder="Your Name" name="text" />
                                            <br />

                                            <label for="email">Your Email*</label>
                                            <input type="email" class="form-control" id="vendor_email" placeholder="Your email" name="email" />
                                            <br />
                                            <label for="email">Your Mobile Number*</label>
                                            <input type="Number" class="form-control" id="vendor_phone" placeholder="Your Mobile Number" name="number" />

                                            <br />
                                            <label for="email">Supplier Type*</label>
                                            <input type="text" class="form-control" id="vendor_desgination" placeholder="Eg : Designer, Architect,...etc" name="text" />

                                            <br />
                                            <label for="email">Current Location*</label>
                                            <input type="text" class="form-control" id="vendor_location" placeholder="Eg : Bangalore, Whitefield, Hyderabad" name="text" />
                                            <br />

                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">How did you hear about this Program?*</label>
                                                <select class="form-control" id="vendor_drop1">
                                                    <option>Newspaper</option>
                                                    <option>Telivision</option>
                                                    <option>Radio</option>
                                                    <option>Outdoor Hoarding</option>
                                                    <option>Email</option>
                                                    <option>SMS</option>
                                                    <option>Facebook/Social Media</option>
                                                    <option>Family and Friends</option>
                                                    <option>Website</option>
                                                </select>
                                            </div>
                                            <br />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <h4 className="aspireblue" > Referral Information </h4>
                                        <div class="form-group  w-85">
                                            <br />
                                            <label for="Name">Referral Name:*</label>
                                            <input type="text" class="form-control" id="vendor_rname" placeholder="Referral Name" name="text" />
                                            <br />

                                            <label for="email">Referral Email:*</label>
                                            <input type="email" class="form-control" id="vendor_remail" placeholder="Referral email" name="email" />
                                            <br />

                                            <label for="email">Referral Number:*</label>
                                            <input type="text" class="form-control" id="vendor_rphone" placeholder="Referral Number" name="number" />
                                            <br />
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Project Want to Refer:*</label>
                                                <select class="form-control" id="vendor_drop2">
                                                    <option>Sumadhura Eden Garden</option>
                                                    <option>Sumadhura Nandanam</option>
                                                    <option>Sumadhura Sushantam</option>
                                                    <option>Sumadhura Soham Phase-2</option>
                                                    <option>Aspire Aurum</option>
                                                    <option>Sumadhura Acropolis</option>
                                                    <option>Sumadhura Horizon</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Unit Type:*</label>
                                                <select class="form-control" id="vendor_drop3">
                                                    <option>1 BHK</option>
                                                    <option>2 BHK</option>
                                                    <option>2.5 BHK</option>
                                                    <option>3 BHK</option>
                                                    <option>3.5 BHK</option>

                                                </select>
                                            </div>
                                            <br />
                                        </div>

                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="row pb-5 mf-e" >
                                    <div className="col-md-12 text-center">
                                        <button className="btn btn-dark md-10" onClick={() => this.vendorScript()}>Submit Details</button>
                                    </div>

                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="row tab-1 text-justify" id="tb4">
                                    <div class="col-md-6">
                                        <h4 className="aspireblue" > Please fill Your details </h4>
                                        <div class="form-group  w-85"><br />

                                            <label for="Name">Your Name*</label>
                                            <input type="text" class="form-control" id="other_name" placeholder="Your Name" name="text" />
                                            <br />

                                            <label for="email">Your Email*</label>
                                            <input type="email" class="form-control" id="other_oemail" placeholder="Your email" name="email" />
                                            <br />
                                            <label for="email">Your Mobile Number*</label>
                                            <input type="Number" class="form-control" id="other_ophone" placeholder="Your Mobile Number" name="number" />
                                            <br />
                                            <label for="email">Current Location*</label>
                                            <input type="text" class="form-control" id="other_olocation" placeholder="Eg : Bangalore, Whitefield, Hyderabad" name="text" />
                                            <br />

                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">How did you hear about this Program?*</label>
                                                <select class="form-control" id="other_odropdown1">
                                                    <option>Newspaper</option>
                                                    <option>Telivision</option>
                                                    <option>Radio</option>
                                                    <option>Outdoor Hoarding</option>
                                                    <option>Email</option>
                                                    <option>SMS</option>
                                                    <option>Facebook/Social Media</option>
                                                    <option>Family and Friends</option>
                                                    <option>Website</option>
                                                </select>
                                            </div>
                                            <br />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <h4 className="aspireblue" >Referral Information </h4>
                                        <div class="form-group  w-85"><br />

                                            <label for="Name">Referral Name:*</label>
                                            <input type="text" class="form-control" id="rother_name" placeholder="Referral Name" name="text" />
                                            <br />

                                            <label for="email">Referral Email:*</label>
                                            <input type="email" class="form-control" id="rother_email" placeholder="Referral Email" name="email" />
                                            <br />

                                            <label for="email">Referral Number:*</label>
                                            <input type="text" class="form-control" id="rother_phone" placeholder="Referral Number" name="number" />
                                            <br />
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Project Want to Refer:*</label>
                                                <select class="form-control" id="rother_dropdown2">
                                                    <option>Sumadhura Eden Garden</option>
                                                    <option>Sumadhura Nandanam</option>
                                                    <option>Sumadhura Sushantam</option>
                                                    <option>Sumadhura Soham Phase-2</option>
                                                    <option>Aspire Aurum</option>
                                                    <option>Sumadhura Acropolis</option>
                                                    <option>Sumadhura Horizon</option>
                                                </select>
                                            </div>

                                            <div class="form-group" >
                                                <label for="exampleFormControlSelect1">Unit Type:*</label>
                                                <select class="form-control" id="rother_dropdown3">
                                                    <option>1 BHK</option>
                                                    <option>2 BHK</option>
                                                    <option>2.5 BHK</option>
                                                    <option>3 BHK</option>
                                                    <option>3.5 BHK</option>

                                                </select>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="row pb-5 mf-e" >
                                    <div className="col-md-12 text-center">
                                        <button className="btn btn-dark md-10" onClick={() => this.otherScript()}>Submit Details</button>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
		<script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
            </Layout >
        )
    }
}
export default referHelper;
